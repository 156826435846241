import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

// Created by FramerOverrides.com - do not redistribute

const useStore = createStore({
    mode: "Monthly",
})

export function withToggleVariant(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        const toggleMode = () => {
            const newMode = store.mode === "Monthly" ? "Yearly" : "Monthly"
            setStore({ mode: newMode })
        }

        return (
            <Component {...props} variant={store.mode} onClick={toggleMode} />
        )
    }
}

export function withReflectVariant(Component): ComponentType {
    return (props) => {
        const [store] = useStore()

        return <Component {...props} variant={store.mode} />
    }
}
